import React from "react";
import RoomIcon from "@mui/icons-material/Room";
export default function Mapa() {
  const direccion =
    "Plaza Gran Vía, Gral. Enrique Ramírez 48-Local 4, Peña, 59300 La Piedad de Cabadas, Mich.";
  const mapaSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8897.731151958018!2d-102.02879744452459!3d20.34016798859304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842c1f138016e9af%3A0x6000895035d23beb!2sFarmacia%20Dermatol%C3%B3gica%20Peroli%20Sucursal%20Madero!5e0!3m2!1ses!2smx!4v1729111317333!5m2!1ses!2smx`;

  return (
    <div style={styles.container}>
      <h2 style={styles.titulo}>¿Cómo llegar?</h2>
      <div style={styles.mapContainer}>
        <iframe
          title="Mapa"
          src={mapaSrc}
          style={styles.map}
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>
      <p style={styles.direccion}>
        <RoomIcon style={styles.icon} />
        En esta dirección: {direccion}
      </p>
    </div>
  );
}

const styles = {
  container: {
    textAlign: "center",
    padding: "20px",
    borderRadius: "10px",
    width: "70%",
    margin: "0 auto",
  },
  titulo: {
    color: "#582217",
    fontSize: "40px",
  },
  direccion: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    marginTop: "10px",
  },
  icon: {
    marginRight: "8px",
    color: "#582217",
  },
  mapContainer: {
    width: "100%",
    height: "400px",
    marginTop: "10px",
    borderRadius: "10px",
  },
  map: {
    width: "100%",
    height: "100%",
    border: "none",
  },
};
