// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MK/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "components/Cards/CounterCards/DefaultCounterCard";

function Conteo() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={1000}
              suffix="+"
              title="Pacientes Satisfechos"
              description="Desde consultas rutinarias hasta tratamientos avanzados, confiamos en nuestra experiencia"
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={50}
              suffix="+"
              title="Tratamientos Ofrecidos"
              description="Desde cuidado de la piel hasta soluciones especializadas, cubrimos todas tus necesidades dermatológicas."
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={10}
              title="Años de Experiencia"
              description="Más de una década brindando atención experta y personalizada para una piel más saludable."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Conteo;
