import React, { useState } from "react";
import Login from "./Login";
import Registro from "./Registro";
import AdminWelcome from "./Admin/AdminWelcome";
import UserWelcome from "./User/UserWelcome";

function SignInBasic() {
  const [conectado, setConectado] = useState(false);
  const [usuario, setUsuario] = useState(null); // Estado para almacenar los datos del usuario
  const [mostrarRegistro, setMostrarRegistro] = useState(false);

  const acceder = (data) => {
    if (data.conectado) {
      setConectado(true);
      setUsuario(data);
      console.log(data);
    }
  };

  return conectado ? (
    usuario.rol === 1 ? (
      <AdminWelcome nombre={usuario.nombre} apellidos={usuario.apellidos} />
    ) : (
      <UserWelcome nombre={usuario.nombre} apellidos={usuario.apellidos} />
    )
  ) : mostrarRegistro ? (
    <Registro acceder={acceder} mostrarLogin={() => setMostrarRegistro(false)} />
  ) : (
    <Login acceder={acceder} mostrarRegistro={() => setMostrarRegistro(true)} />
  );
}

export default SignInBasic;
