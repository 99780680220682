import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MK/MKBox";

// Material Kit 2 React examples
import RotatingCard from "components/Cards/RotatingCard";
import RotatingCardFront from "components/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "components/Cards/RotatingCard/RotatingCardBack";

import hf from "assets/images/Cards/hydrofacialf.avif";
import hb from "assets/images/Cards/hydrofacialb.jpg";

import nf from "assets/images/Cards/nanoporef.jpg";
import nb from "assets/images/Cards/nanoporeb.jpg";

import df from "assets/images/Cards/depilacionf.jpg";
import db from "assets/images/Cards/depilacionb.jpg";

export default function Cards() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={hf}
                icon="water_drop"
                title={<>Hydrafacial</>}
                description="Tratamiento facial avanzado para una piel radiante y saludable."
              />
              <RotatingCardBack
                image={hb}
                title="Experimenta Hydrafacial"
                description="El tratamiento Hydrafacial limpia, exfolia e hidrata tu piel con resultados visibles y duraderos. Ideal para todo tipo de pieles."
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={nf}
                icon="science"
                title={<>Nanopore</>}
                description="Tecnologia avanzada para mejorar la textura y aparencia de tu piel."
              />
              <RotatingCardBack
                image={nb}
                title="Revitaliza tu piel con Nanopore"
                description="El tratamiento Nanopore utiliza microneedling para estimular la producción de colágeno y mejorar la textura de la piel. Ideal para reducir arrugas y cicatrices."
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={df}
                icon="flash_on"
                title={<>Depilacion Laser</>}
                description="Solucion efectiva y duradera para la eliminacion del vello no deseado"
              />
              <RotatingCardBack
                image={db}
                title="Di Adiós al Vello"
                description="La depilación láser diodo ofrece resultados duraderos y confortables, ideal para una piel suave y libre de vello. Apta para todas las áreas del cuerpo."
              />
            </RotatingCard>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
