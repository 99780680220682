import Nosotros from "./pages/Nosotros/Nosotros";
import Contactanos from "./pages/Contactanos/Contactanos";
import SignIn from "./pages/Login/SignIn";
import Inicio from "./pages/Inicio/Inicio";
import Dermatologia from "./pages/Dermatologia/Dermatologia"; // Importa el nuevo componente

const routes = [
  {
    key: "inicio", // Clave única
    name: "INICIO",
    route: "/",
    component: <Inicio />,
  },
  {
    key: "dermatologia", // Clave única
    name: "DERMATOLOGIA",
    route: "/dermatologia",
    component: <Dermatologia />,
  },
  {
    key: "nosotros", // Clave única
    name: "NOSOTROS",
    route: "/nosotros",
    component: <Nosotros />,
  },
  {
    key: "contactanos", // Clave única
    name: "CONTACTANOS",
    route: "/contactanos",
    component: <Contactanos />,
  },
  {
    key: "login", // Clave única
    name: "LOGIN",
    route: "/login",
    component: <SignIn />,
  },
];

export default routes;
