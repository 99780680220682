import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import MKBox from "components/MK/MKBox";
import MKTypography from "components/MK/MKTypography";
import bgImage from "assets/images/Paginas/Admin.jpg";
import "./AdminWelcome.css";

const URL_USUARIOS = "https://dermamirta.com/backend/usuarios.php";

function AdminWelcome({ nombre, apellidos }) {
  const [usuarios, setUsuarios] = useState([]);
  const [nuevoUsuario, setNuevoUsuario] = useState({
    correo: "",
    nombre: "",
    apellidos: "",
    clave: "",
    idTipoUsuario: 2, // Por defecto, los nuevos usuarios serán de tipo Usuario
  });
  const [editandoUsuario, setEditandoUsuario] = useState(null);
  const [formUsuario, setFormUsuario] = useState({
    correo: "",
    nombre: "",
    apellidos: "",
    idTipoUsuario: 2,
  });
  const [filtro, setFiltro] = useState("");
  const [errores, setErrores] = useState({});
  const [orden, setOrden] = useState({ campo: null, ascendente: true });
  const [mostrarFormulario, setMostrarFormulario] = useState(false);

  // Cargar usuarios al montar el componente
  useEffect(() => {
    const obtenerUsuarios = async () => {
      const response = await fetch(URL_USUARIOS);
      const data = await response.json();
      setUsuarios(data);
    };
    obtenerUsuarios();
  }, []);

  const validarFormulario = () => {
    const nuevosErrores = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!nuevoUsuario.correo) {
      nuevosErrores.correo = "El correo es obligatorio.";
    } else if (!emailRegex.test(nuevoUsuario.correo)) {
      nuevosErrores.correo = "Ingrese un correo válido.";
    }

    if (!nuevoUsuario.nombre) nuevosErrores.nombre = "El nombre es obligatorio.";
    if (!nuevoUsuario.apellidos) nuevosErrores.apellidos = "Los apellidos son obligatorios.";
    if (!nuevoUsuario.clave) nuevosErrores.clave = "La clave es obligatoria.";

    setErrores(nuevosErrores);
    return Object.keys(nuevosErrores).length === 0;
  };

  // Crear un nuevo usuario
  const crearUsuario = async () => {
    if (!validarFormulario()) return;

    const usuarioParaCrear = { ...nuevoUsuario, idTipoUsuario: 2 };
    const response = await fetch(URL_USUARIOS, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(usuarioParaCrear),
    });
    const data = await response.json();
    if (data.registrado) {
      setUsuarios([...usuarios, { ...usuarioParaCrear, id: Date.now() }]);
      setNuevoUsuario({ correo: "", nombre: "", apellidos: "", clave: "", idTipoUsuario: 2 });
      setErrores({});
      setMostrarFormulario(false);
    }
  };

  // Actualizar un usuario existente
  const actualizarUsuario = async (id) => {
    const response = await fetch(`${URL_USUARIOS}?id=${id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formUsuario),
    });
    const data = await response.json();
    if (data.actualizado) {
      setUsuarios(
        usuarios.map((usuario) => (usuario.id === id ? { ...usuario, ...formUsuario } : usuario))
      );
      setEditandoUsuario(null);
    }
  };

  // Eliminar un usuario
  const eliminarUsuario = async (id) => {
    const response = await fetch(`${URL_USUARIOS}?id=${id}`, { method: "DELETE" });
    const data = await response.json();
    if (data.eliminado) {
      setUsuarios(usuarios.filter((usuario) => usuario.id !== id));
    }
  };

  // // Filtrar usuarios
  // const usuariosFiltrados = usuarios.filter(
  //   (usuario) =>
  //     usuario.nombre.toLowerCase().includes(filtro.toLowerCase()) ||
  //     usuario.correo.toLowerCase().includes(filtro.toLowerCase()) ||
  //     usuario.apellidos.toLowerCase().includes(filtro.toLowerCase())
  // );

  const usuariosFiltrados = useMemo(() => {
    const filtroEnMinuscula = filtro.toLowerCase();
    return usuarios
      .filter((usuario) =>
        ["nombre", "apellidos", "correo"].some((campo) =>
          usuario[campo].toLowerCase().includes(filtroEnMinuscula)
        )
      )
      .sort((a, b) => {
        if (!orden.campo) return 0;
        const campoA = a[orden.campo].toString().toLowerCase();
        const campoB = b[orden.campo].toString().toLowerCase();
        if (campoA < campoB) return orden.ascendente ? -1 : 1;
        if (campoA > campoB) return orden.ascendente ? 1 : -1;
        return 0;
      });
  }, [filtro, usuarios, orden]);

  const handleOrdenar = (campo) => {
    setOrden((prev) => ({
      campo,
      ascendente: prev.campo === campo ? !prev.ascendente : true,
    }));
  };

  return (
    <>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography variant="h1" color="white">
              Bienvenido, {nombre} {apellidos} (Admin)
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Administración de Usuarios
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      <Card sx={{ p: 2, mx: { xs: 2, lg: 3 }, mt: -8, mb: 4 }}>
        <div className="admin-container">
          <h3>Lista de Usuarios</h3>

          {mostrarFormulario && (
            <div className="form-usuario">
              <input
                type="text"
                placeholder="Correo"
                value={nuevoUsuario.correo}
                onChange={(e) => setNuevoUsuario({ ...nuevoUsuario, correo: e.target.value })}
              />
              {errores.correo && <p className="error">{errores.correo}</p>}

              <input
                type="text"
                placeholder="Nombre"
                value={nuevoUsuario.nombre}
                onChange={(e) => setNuevoUsuario({ ...nuevoUsuario, nombre: e.target.value })}
              />
              {errores.nombre && <p className="error">{errores.nombre}</p>}

              <input
                type="text"
                placeholder="Apellidos"
                value={nuevoUsuario.apellidos}
                onChange={(e) => setNuevoUsuario({ ...nuevoUsuario, apellidos: e.target.value })}
              />
              {errores.apellidos && <p className="error">{errores.apellidos}</p>}

              <input
                type="password"
                placeholder="Clave"
                value={nuevoUsuario.clave}
                onChange={(e) => setNuevoUsuario({ ...nuevoUsuario, clave: e.target.value })}
              />
              {errores.clave && <p className="error">{errores.clave}</p>}

              <button onClick={crearUsuario}>Crear Usuario</button>
            </div>
          )}
          <div className="busqueda-container">
            <input
              type="text"
              placeholder="Buscar por nombre, correo o apellidos..."
              value={filtro}
              onChange={(e) => setFiltro(e.target.value)}
              className="input-busqueda"
            />
            <IconButton
              onClick={() => setMostrarFormulario(!mostrarFormulario)}
              className="boton-crear"
            >
              <AddIcon />
              <span className="texto-boton">Crear Usuario</span>
            </IconButton>
          </div>
          <table className="tabla-usuarios">
            <thead>
              <tr>
                {["nombre", "apellidos", "correo", "idTipoUsuario"].map((campo, index) => (
                  <th
                    key={index}
                    onClick={() => handleOrdenar(campo)}
                    style={{ cursor: "pointer" }}
                  >
                    {campo.charAt(0).toUpperCase() + campo.slice(1)}
                    {orden.campo === campo && (orden.ascendente ? "🡩" : "🡫")}
                  </th>
                ))}
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {usuariosFiltrados.length > 0 ? (
                usuariosFiltrados.map((usuario) => (
                  <tr key={usuario.id}>
                    {editandoUsuario === usuario.id ? (
                      <>
                        <td>
                          <input
                            type="text"
                            value={formUsuario.nombre}
                            onChange={(e) =>
                              setFormUsuario({ ...formUsuario, nombre: e.target.value })
                            }
                            className="input-editar"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={formUsuario.apellidos}
                            onChange={(e) =>
                              setFormUsuario({ ...formUsuario, apellidos: e.target.value })
                            }
                            className="input-editar"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={formUsuario.correo}
                            onChange={(e) =>
                              setFormUsuario({ ...formUsuario, correo: e.target.value })
                            }
                            className="input-editar"
                          />
                        </td>
                        <td>
                          <select
                            value={formUsuario.idTipoUsuario}
                            onChange={(e) =>
                              setFormUsuario({ ...formUsuario, idTipoUsuario: e.target.value })
                            }
                            className="input-editar"
                          >
                            <option value="1">Administrador</option>
                            <option value="2">Usuario</option>
                          </select>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{usuario.nombre}</td>
                        <td>{usuario.apellidos}</td>
                        <td>{usuario.correo}</td>
                        <td>{usuario.idTipoUsuario === "1" ? "Administrador" : "Usuario"}</td>
                      </>
                    )}
                    <td>
                      {editandoUsuario === usuario.id ? (
                        <>
                          <button onClick={() => actualizarUsuario(usuario.id)}>
                            <SaveIcon />
                          </button>
                          <button onClick={() => setEditandoUsuario(null)}>
                            {" "}
                            <CancelIcon />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              setEditandoUsuario(usuario.id);
                              setFormUsuario({
                                correo: usuario.correo,
                                nombre: usuario.nombre,
                                apellidos: usuario.apellidos,
                                idTipoUsuario: usuario.idTipoUsuario,
                              });
                            }}
                          >
                            <EditIcon />
                          </button>
                          <button onClick={() => eliminarUsuario(usuario.id)}>
                            <DeleteIcon />
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="no-resultados">
                    No se encontraron resultados
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Card>
    </>
  );
}

AdminWelcome.propTypes = {
  nombre: PropTypes.string.isRequired,
  apellidos: PropTypes.string.isRequired,
};

export default AdminWelcome;
