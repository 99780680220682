// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MK/MKBox";
import MKTypography from "components/MK/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "components/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/Doctores/Dr1.jpg";
import team2 from "assets/images/Doctores/Dr2.jpg";
import team3 from "assets/images/Doctores/Dr3.jpg";
import team4 from "assets/images/Doctores/Dr4.jpg";

function EquipoDoc() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="white"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="info">
              Conoce a Nuestros Doctores
            </MKTypography>
            <MKTypography variant="body" opacity={0.8}>
              Nuestro equipo de expertos está dedicado a ofrecer el mejor cuidado para tu piel. Cada
              doctor aporta una gran experiencia y un compromiso con la excelencia.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Dra. Emma Roberts"
                position={{ color: "info", label: "Dermatóloga" }}
                description="La Dra. Roberts es conocida por su experiencia en dermatología estética y tiene años de experiencia en mejorar la salud de la piel."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Dr. William Pearce"
                position={{ color: "info", label: "Dermatólogo Clínico" }}
                description="El Dr. Pearce se especializa en dermatología clínica y tiene una sólida formación en el tratamiento de una amplia gama de condiciones de la piel."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Dra. Ivana Flow"
                position={{ color: "info", label: "Dermatóloga Cosmética" }}
                description="La Dra. Flow se enfoca en procedimientos estéticos y es conocida por sus enfoques innovadores para la rejuvenecimiento de la piel."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Dr. Marquez Garcia"
                position={{ color: "info", label: "Dermatólogo Pediátrico" }}
                description="El Dr. Garcia es un experto en dermatología pediátrica, dedicado a ofrecer cuidados especializados para pacientes jóvenes."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default EquipoDoc;
