import React from "react";
import "./Information.css"; // Asegúrate de crear este archivo CSS

// Imágenes (reemplaza estas rutas con tus imágenes)
import esteticaImg from "assets/images/dermatologia/estetica.jpg"; // Cambia la ruta según tu estructura
import clinicaImg from "assets/images/dermatologia/clinica.jpg";
import cancerImg from "assets/images/dermatologia/cancer.jpg";
import consultaImg from "assets/images/dermatologia/consulta.jpg";
import cirugiaImg from "assets/images/dermatologia/cirugia.jpg";

function Information() {
  return (
    <div className="information-section">
      <h2>Secciones de Dermatología</h2>
      <p>
        En nuestra sección de Dermatología, ofrecemos una variedad de servicios especializados para
        el cuidado de la piel. A continuación, encontrarás detalles sobre cada uno de nuestros
        servicios.
      </p>
      <div className="info-cards">
        <div className="info-card">
          <div className="info-content">
            <h1>Medicina Dermatológica Estética</h1>
            <p>
              La Medicina Dermatológica Estética se centra en tratamientos no quirúrgicos para
              mejorar la apariencia de la piel. Esto incluye el uso de rellenos dérmicos, Botox y
              tratamientos láser que ayudan a rejuvenecer la piel y tratar diversas imperfecciones.
            </p>
            <strong>Ejemplos de Tratamientos:</strong>
            <ul>
              <li>
                <strong>Rellenos Dérmicos:</strong> Agregan volumen a los labios y mejillas.
              </li>
              <li>
                <strong>Botox:</strong> Reduce las arrugas y líneas de expresión.
              </li>
              <li>
                <strong>Tratamientos Láser:</strong> Mejoran la textura de la piel y eliminan
                manchas.
              </li>
            </ul>
          </div>
          <img src={esteticaImg} alt="Medicina Dermatológica Estética" />
        </div>
        <div className="info-card">
          <div className="info-content">
            <h1>Medicina Dermatológica Clínica</h1>
            <p>
              Brindamos atención integral para condiciones médicas de la piel, como eczema,
              psoriasis y acné. Nuestros dermatólogos especializados diagnostican y tratan
              eficazmente estas condiciones para mejorar la calidad de vida de nuestros pacientes.
            </p>
            <strong>Ejemplos de Tratamientos:</strong>
            <ul>
              <li>
                <strong>Tratamientos Tópicos:</strong> Cremas para controlar el acné y dermatitis.
              </li>
              <li>
                <strong>Medicamentos Orales:</strong> Utilizados en casos severos de psoriasis.
              </li>
              <li>
                <strong>Fototerapia:</strong> Tratamiento con luz UV para afecciones como el eczema.
              </li>
            </ul>
          </div>
          <img src={clinicaImg} alt="Medicina Dermatológica Clínica" />
        </div>
        <div className="info-card">
          <div className="info-content">
            <h1>Cáncer de Piel</h1>
            <p>
              Detección y tratamiento del cáncer de piel, incluyendo melanoma y carcinomas no
              melanomatosos. La detección temprana es esencial para el tratamiento eficaz y la
              prevención de la propagación.
            </p>
            <strong>Ejemplos de Tratamientos:</strong>
            <ul>
              <li>
                <strong>Exámenes de Piel:</strong> Evaluaciones para detectar signos tempranos de
                cáncer.
              </li>
              <li>
                <strong>Cirugía:</strong> Extracción de tumores y márgenes de piel.
              </li>
              <li>
                <strong>Terapia Tópica:</strong> Medicamentos aplicados sobre lesiones cancerosas.
              </li>
            </ul>
          </div>
          <img src={cancerImg} alt="Cáncer de Piel" />
        </div>
        <div className="info-card">
          <div className="info-content">
            <h1>Consulta Dermatológica</h1>
            <p>
              Consultas personalizadas para diagnosticar y tratar afecciones dermatológicas. Durante
              la consulta, nuestros dermatólogos evalúan la historia clínica y realizan un examen
              físico para determinar el tratamiento adecuado.
            </p>
            <strong>Ejemplos de Evaluaciones:</strong>
            <ul>
              <li>
                <strong>Evaluación de Lesiones:</strong> Revisión de lunares y manchas sospechosas.
              </li>
              <li>
                <strong>Historial Médico Completo:</strong> Análisis de antecedentes familiares.
              </li>
              <li>
                <strong>Pruebas de Alergia:</strong> Determinación de alergias cutáneas.
              </li>
            </ul>
          </div>
          <img src={consultaImg} alt="Consulta Dermatológica" />
        </div>
        <div className="info-card">
          <div className="info-content">
            <h1>Cirugía Dermatológica</h1>
            <p>
              Realizamos procedimientos quirúrgicos para tratar afecciones de la piel, incluyendo la
              eliminación de lunares y lesiones. La cirugía dermatológica se lleva a cabo en un
              entorno seguro y controlado, con el objetivo de obtener resultados óptimos.
            </p>
            <strong>Ejemplos de Procedimientos:</strong>
            <ul>
              <li>
                <strong>Excisiones Quirúrgicas:</strong> Extracción de lunares y quistes.
              </li>
              <li>
                <strong>Curetaje:</strong> Eliminación de lesiones precoces de la piel.
              </li>
              <li>
                <strong>Reconstrucción:</strong> Técnicas para restaurar la apariencia de la piel.
              </li>
            </ul>
          </div>
          <img src={cirugiaImg} alt="Cirugía Dermatológica" />
        </div>
      </div>
    </div>
  );
}

export default Information;
