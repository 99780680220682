import React from "react";
import PropTypes from "prop-types";
export default function UserWelcome({ nombre, apellidos }) {
  return (
    <div>
      <h1>Bienvenido, {(nombre, apellidos)}(Usuario)</h1>
      <p>Eres usuario (Rol 2). Tienes acceso limitado.</p>
    </div>
  );
}
UserWelcome.propTypes = {
  nombre: PropTypes.string.isRequired,
  apellidos: PropTypes.string.isRequired,
};
