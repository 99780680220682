/* eslint-disable react/prop-types */
import { useRef, useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MKBox from "components/MK/MKBox";
import MKTypography from "components/MK/MKTypography";
import MKButton from "components/MK/MKButton";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import routes from "routes";
import bgImage from "../../assets/images/Paginas/Sigin.jpg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import BotonFlotante from "components/BotonFlotante/BotonFlotante";

const URL_LOGIN = "https://dermamirta.com/backend/login.php";

const enviarData = async (url, data) => {
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    });

    if (!resp.ok) {
      const errorText = await resp.text();
      throw new Error(`Error en la respuesta del servidor: ${resp.status} ${errorText}`);
    }

    return await resp.json();
  } catch (error) {
    console.error("Error al enviar los datos:", error);
    return { error: error.message };
  }
};

export default function Login({ acceder, mostrarRegistro }) {
  const [error, setError] = useState(null);
  const [espera, setEspera] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const refUsuario = useRef(null);
  const refClave = useRef(null);

  const handleLogin = async () => {
    setEspera(true);
    const data = {
      usuario: refUsuario.current.value,
      clave: refClave.current.value,
    };
    const respJson = await enviarData(URL_LOGIN, data);
    if (respJson.conectado) {
      acceder({
        conectado: true,
        nombre: respJson.nombre,
        apellidos: respJson.apellidos,
        rol: respJson.idTipoUsuario,
      });
    } else {
      setError(respJson.error);
    }
    setEspera(false);
  };

  // Limpia el mensaje de error después de 5 segundos o si el usuario empieza a escribir
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Iniciar Sesión
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKTypography variant="subtitle1" fontWeight="medium" mb={1}>
                      Correo
                    </MKTypography>
                    <input
                      type="email"
                      ref={refUsuario}
                      autoComplete="username" // Agregado
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #cccccc4b",
                        fontSize: "14px",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        transition: "border-color 0.3s ease",
                      }}
                      placeholder="Ingrese su correo"
                      onFocus={(e) => (e.target.style.borderColor = "#2196F3")}
                      onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                    />
                  </MKBox>

                  <MKBox mb={2}>
                    <MKTypography variant="subtitle1" fontWeight="medium" mb={1}>
                      Contraseña
                    </MKTypography>
                    <MKBox position="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        ref={refClave}
                        autoComplete="current-password" // Agregado
                        style={{
                          width: "100%",
                          padding: "10px",
                          paddingRight: "40px",
                          borderRadius: "5px",
                          border: "1px solid #cccccc4b",
                          fontSize: "14px",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          transition: "border-color 0.3s ease",
                        }}
                        placeholder="Ingrese su contraseña"
                        onFocus={(e) => (e.target.style.borderColor = "#2196F3")}
                        onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                      />
                      <MKBox
                        position="absolute"
                        right="12px"
                        top="15%"
                        transform="translateY(-50%)"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ cursor: "pointer" }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </MKBox>
                    </MKBox>
                  </MKBox>

                  {error && (
                    <MKBox
                      mb={1}
                      p={1}
                      borderRadius="lg"
                      color="white"
                      shadow="sm"
                      textAlign="center"
                      sx={{
                        backgroundColor: "rgb(231, 0, 0)",
                      }}
                    >
                      ¡{error}!
                    </MKBox>
                  )}

                  <MKBox mt={4} mb={1}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      disabled={espera}
                      onClick={handleLogin}
                    >
                      Entrar
                    </MKButton>
                  </MKBox>
                  <MKBox mt={2} textAlign="center">
                    <MKTypography variant="body2" color="text">
                      ¿No tienes cuenta?{" "}
                      <span
                        onClick={mostrarRegistro}
                        style={{ color: "#00f", cursor: "pointer", textDecoration: "none" }}
                      >
                        Registrarse
                      </span>
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
        <BotonFlotante />
      </MKBox>
    </>
  );
}
