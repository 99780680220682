// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MK/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "components/Cards/CounterCards/DefaultCounterCard";

function Conteo() {
  return (
    <MKBox component="section" pt={3} pb={8}>
      <Container>
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={5234}
              separator=","
              title="Pacientes Atendidos"
              description="Número total de pacientes que hemos atendido con éxito.
"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={3400}
              separator=","
              suffix="+"
              title="Horas de Tratamiento"
              description="Horas dedicadas a proporcionar cuidados y tratamientos especializados."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={24}
              suffix="/7"
              title="Consultas"
              description="Consultas realizadas por nuestro equipo de especialistas en dermatología."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Conteo;
