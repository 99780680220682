import Card from "@mui/material/Card";
import MKBox from "components/MK/MKBox";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";
import Conteo from "pages/Inicio/sections/Conteo";
import Cards from "pages/Inicio/sections/Cards";
import Testimonios from "pages/Inicio/sections/Testimonios";
import BotonFlotante from "../../components/BotonFlotante/BotonFlotante";
import CarruselDeImagenes from "pages/Inicio/sections/CarruselDeImagenes";
import ReseñaDoctor from "pages/Inicio/sections/ReseñaDoctor";
import Mapa from "pages/Inicio/sections/Mapa";
import BandaPromociones from "pages/Inicio/sections/BandaPromociones";
import routes from "routes";
export default function Inicio() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <CarruselDeImagenes />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <BandaPromociones />
        <Conteo />
        <Cards />
        <ReseñaDoctor />
        <Mapa />
        <Testimonios />
      </Card>
      <BotonFlotante />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter />
      </MKBox>
    </>
  );
}
