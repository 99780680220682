import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MK/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "components/Cards/BlogCards/CenteredBlogCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="verified"
                    title="Centro de Excelencia"
                    description="Nuestro centro dermatológico se dedica a proporcionar el mejor cuidado para tu piel, combinando tecnología avanzada y experiencia profesional."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="verified"
                    title="Especialistas Certificados"
                    description="Contamos con un equipo de dermatólogos altamente calificados y certificados, comprometidos con la excelencia en el tratamiento de diversas condiciones de la piel."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="build"
                    title="Servicios Completos"
                    description="Desde consultas generales hasta tratamientos estéticos avanzados, ofrecemos una gama completa de servicios para satisfacer todas tus necesidades dermatológicas."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="person"
                    title="Atención Personalizada"
                    description="Cada paciente recibe una atención personalizada, con un plan de tratamiento adaptado a sus necesidades específicas para asegurar los mejores resultados posibles."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Nuestra Misión"
              description="Otorgar atención médica de calidad, con recursos especializados y tecnología de vanguardia; fomentando en su personal el humanismo, desarrollo, innovación y excelencia, para lograr la seguridad del paciente, su satisfacción y confianza."
            />
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Nuestra Visión"
              description="Ser una empresa reconocida por sus pacientes, clientes, profesionales y en el entorno social como una organización excelente, responsable, eficiente y competitiva, comprometida con la seguridad y la salud de las personas."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
