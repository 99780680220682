import React from "react";
import { Box, Typography } from "@mui/material";
import Dra from "assets/images/Doctores/Dra.jpg";
import "./ReseñaDoctor.css";

export default function ReseñaDoctor() {
  const doctor = {
    name: "Dra. Mirta de Lourdes Pérez Olivos",
    level: "Dermatóloga",
    description:
      "La Dra. Mirta de Lourdes Pérez Olivos es una especialista reconocida en el tratamiento de enfermedades de la piel, cabello y uñas. Cuenta con certificaciones en Tricología, Dermatología Estética y Dermatología Pediátrica. Con su experiencia en la salud capilar, es experta en tratar afecciones como la caída del cabello y la alopecia. Además, su especialización en dermatología estética le permite ofrecer tratamientos avanzados para mejorar la apariencia y la salud de la piel. Como dermatóloga pediátrica, se dedica también al cuidado de la piel de los más pequeños, abordando condiciones comunes con un enfoque sensible y empático.",
    photo: Dra,
  };

  return (
    <Box className="container">
      <Box className="row">
        <Box className="textContainer">
          <Typography variant="h2" className="name">
            {doctor.name}
          </Typography>
          <Typography variant="h5" className="level">
            {doctor.level}
          </Typography>
          <Typography variant="body1" className="description">
            {doctor.description}
          </Typography>
        </Box>
        <Box className="photoContainer">
          <img src={doctor.photo} alt="Doctora Fundadora" className="photo" />
        </Box>
      </Box>
    </Box>
  );
}
