import React, { useState } from "react";
import "./BotonFlotante.css";

export default function BotonFlotante() {
  const [fabOpened, setFabOpened] = useState(false);

  const toggleFab = () => {
    setFabOpened(!fabOpened);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={`fab-container ${fabOpened ? "fab-opened" : ""}`}>
      <button className="fab-button" onClick={toggleFab}>
        <i className={fabOpened ? "fas fa-times" : "fas fa-plus"}></i>
      </button>

      {fabOpened && (
        <div className="fab-actions">
          <a
            href="https://wa.me/1234567890"
            target="_blank"
            rel="noopener noreferrer"
            className="fab-action"
            title="WhatsApp"
          >
            <i className="fab fa-whatsapp"></i>
          </a>
          <a
            href="https://maps.app.goo.gl/DK5bmkXS5gCNzQkd9"
            target="_blank"
            rel="noopener noreferrer"
            className="fab-action"
            title="Ubicación"
          >
            <i className="fas fa-map-marker-alt"></i>
          </a>
          <button className="fab-action" onClick={scrollToTop} title="Volver arriba">
            <i className="fas fa-arrow-up"></i>
          </button>
        </div>
      )}
    </div>
  );
}
