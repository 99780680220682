import React, { useEffect, useRef } from "react";

export default function BandaPromociones() {
  const promociones = [
    "¡ENVÍO GRATIS EN COMPRAS MAYORES A $600 PESOS!",
    "3 MESES SIN INTERESES EN COMPRAS CON TARJETA DE CRÉDITO.",
    "DESCUENTO DEL 10% EN TU PRIMERA COMPRA, USA EL CÓDIGO: PRIMERA10.",
    "COMPRA 2 Y LLEVA EL 3ER PRODUCTO CON UN 50% DE DESCUENTO.",
    "ENVÍOS EXPRESS EN TODO EL PAÍS POR SOLO $100 PESOS.",
  ];

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let startPosition = container.offsetWidth;

    const animate = () => {
      if (startPosition < -container.scrollWidth) {
        startPosition = container.offsetWidth;
      }
      startPosition -= 1.5;
      container.style.transform = `translateX(${startPosition}px)`;
      requestAnimationFrame(animate);
    };

    animate();
  }, []);

  return (
    <div style={styles.wrapper}>
      <div style={styles.container} ref={containerRef}>
        {promociones.map((promo, index) => (
          <span key={index} style={styles.promo}>
            {promo}
          </span>
        ))}
      </div>
    </div>
  );
}

const styles = {
  wrapper: {
    width: "100%",
    overflow: "hidden",
    backgroundColor: "#582217",
    border: "1px solid #ddd",
    padding: "10px 0",
  },
  container: {
    display: "flex",
    whiteSpace: "nowrap",
    fontSize: "24px",
    color: "#FFF",
    padding: "0 20px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  promo: {
    padding: "0 30px",
  },
};
