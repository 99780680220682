/* eslint-disable react/prop-types */
import { useRef, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MKBox from "components/MK/MKBox";
import MKTypography from "components/MK/MKTypography";
import MKButton from "components/MK/MKButton";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import routes from "routes";
import bgImage from "../../assets/images/Paginas/Sigin.jpg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import BotonFlotante from "components/BotonFlotante/BotonFlotante";

const URL_REGISTRO = "https://dermamirta.com/backend/registrar.php";

const enviarData = async (url, data) => {
  const resp = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
  const json = await resp.json();

  return json;
};

export default function Registro({ acceder, mostrarLogin }) {
  const [error, setError] = useState(null);
  const [espera, setEspera] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const refCorreo = useRef(null);
  const refNombre = useRef(null);
  const refApellidos = useRef(null);
  const refClave = useRef(null);

  const handleRegistro = async () => {
    const correo = refCorreo.current.value.trim();
    const nombre = refNombre.current.value.trim();
    const apellidos = refApellidos.current.value.trim();
    const clave = refClave.current.value.trim();

    if (!correo || !nombre || !apellidos || !clave) {
      setError("Todos los campos son obligatorios.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(correo)) {
      setError("Por favor, ingrese un correo electrónico válido.");
      return;
    }
    console.log({ correo, nombre, apellidos, clave });

    setError(null);
    setEspera(true);

    const data = {
      correo,
      nombre,
      apellidos,
      clave,
    };

    const respJson = await enviarData(URL_REGISTRO, data);
    if (respJson.registrado) {
      acceder(true);
    } else {
      setError(respJson.error);
    }
    setEspera(false);
  };

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Registrarse
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKTypography
                      variant="subtitle1"
                      fontWeight="medium"
                      color="textSecondary"
                      mb={1}
                    >
                      Correo
                    </MKTypography>
                    <input
                      type="email"
                      ref={refCorreo}
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #cccccc4b",
                        fontSize: "16px",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        transition: "border-color 0.3s ease",
                      }}
                      placeholder="Ingrese su correo"
                      onFocus={(e) => (e.target.style.borderColor = "#2196F3")}
                      onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                    />
                  </MKBox>

                  <MKBox mb={2}>
                    <MKTypography
                      variant="subtitle1"
                      fontWeight="medium"
                      color="textSecondary"
                      mb={1}
                    >
                      Nombre
                    </MKTypography>
                    <input
                      type="text"
                      ref={refNombre}
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #cccccc4b",
                        fontSize: "16px",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        transition: "border-color 0.3s ease",
                      }}
                      placeholder="Ingrese su nombre"
                      onFocus={(e) => (e.target.style.borderColor = "#2196F3")}
                      onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                    />
                  </MKBox>

                  <MKBox mb={2}>
                    <MKTypography
                      variant="subtitle1"
                      fontWeight="medium"
                      color="textSecondary"
                      mb={1}
                    >
                      Apellidos
                    </MKTypography>
                    <input
                      type="text"
                      ref={refApellidos}
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #cccccc4b",
                        fontSize: "16px",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        transition: "border-color 0.3s ease",
                      }}
                      placeholder="Ingrese sus apellidos"
                      onFocus={(e) => (e.target.style.borderColor = "#2196F3")}
                      onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                    />
                  </MKBox>

                  <MKBox mb={2}>
                    <MKTypography
                      variant="subtitle1"
                      fontWeight="medium"
                      color="textSecondary"
                      mb={1}
                    >
                      Contraseña
                    </MKTypography>
                    <MKBox position="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        ref={refClave}
                        style={{
                          width: "100%",
                          padding: "10px",
                          paddingRight: "40px",
                          borderRadius: "5px",
                          border: "1px solid #cccccc4b",
                          fontSize: "14px",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          transition: "border-color 0.3s ease",
                        }}
                        placeholder="Ingrese su contraseña"
                        onFocus={(e) => (e.target.style.borderColor = "#2196F3")}
                        onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                      />
                      <MKBox
                        position="absolute"
                        right="12px"
                        top="15%"
                        transform="translateY(-50%)"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ cursor: "pointer" }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}{" "}
                      </MKBox>
                    </MKBox>
                  </MKBox>

                  {error && (
                    <div
                      style={{
                        color: "red",
                        backgroundColor: "rgba(255, 0, 0, 0.1)",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      {error}
                    </div>
                  )}
                  <MKBox mt={4} mb={1}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      disable={espera}
                      onClick={handleRegistro}
                    >
                      Registrarse
                    </MKButton>
                  </MKBox>
                  <MKBox mt={2} textAlign="center">
                    <MKTypography variant="body2" color="text">
                      ¿Ya tienes cuenta?{" "}
                      <span
                        onClick={mostrarLogin}
                        style={{ color: "#00f", cursor: "pointer", textDecoration: "none" }}
                      >
                        Iniciar sesión
                      </span>
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <BotonFlotante />
    </>
  );
}
