import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import MKBox from "components/MK/MKBox";
import MKTypography from "components/MK/MKTypography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const images = [
  {
    src: "https://img.freepik.com/foto-gratis/mujer-recibiendo-tratamiento-peluqueria_23-2149229771.jpg?t=st=1729112857~exp=1729116457~hmac=df532165b29965bf13dd36b057cdb1235d39d2a79dd2a1223aecc46c30801bb6&w=1380", // Cambia esto con tus imágenes
    title: "Peroli",
    description: "Centro Dermatológico: Cuidado experto para una piel saludable y radiante.",
  },
  {
    src: "https://img.freepik.com/foto-gratis/mujer-sonriente-tiro-medio-posando-espacio-copia_23-2149394089.jpg?t=st=1729112928~exp=1729116528~hmac=721e52140c2024b8babdd64811bed69f7a8a60a8769dbf36675a3fb26d42afec&w=1380",
    title: "Cuidado de la Piel",
    description: "Productos dermatológicos de calidad para cada tipo de piel.",
  },
  {
    src: "https://img.freepik.com/foto-gratis/mujer-recibiendo-tratamiento-peluqueria_23-2149229766.jpg?t=st=1729112885~exp=1729116485~hmac=a143b8b2924b840385670a7da6728691503117bd1d2ef4cff936a41b4fcbfc09&w=1380",
    title: "Tratamientos Avanzados",
    description: "Tecnología avanzada para el tratamiento de afecciones cutáneas.",
  },
  {
    src: "https://img.freepik.com/foto-gratis/grupo-medicos-exitosos-hospital_1139-315.jpg?t=st=1729112961~exp=1729116561~hmac=9d03f240aa5c5cae9d340d9c85f2866a52f7deba5d45e1a6da2861f487b41939&w=1380",
    title: "Equipo Profesional",
    description: "Un equipo de expertos en dermatología siempre a tu disposición.",
  },
  {
    src: "https://img.freepik.com/foto-gratis/vista-frontal-novia-sonriente-interior_23-2149721969.jpg?t=st=1729112991~exp=1729116591~hmac=6c1fbfae672cd3b44974c665fc911fe483a59c8082faa1764f6a758863aa5e01&w=1380",
    title: "Resultados Garantizados",
    description: "Nuestra prioridad es mejorar la salud y el aspecto de tu piel.",
  },
];

export default function CarruselDeImagenes() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const { src, title, description } = images[currentIndex];

  return (
    <div style={styles.carousel}>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          position: "relative",
          backgroundImage: `url(${src})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div style={styles.overlay}></div>
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {title}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              {description}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      <div style={styles.controls}>
        <button onClick={handlePrev} style={styles.button}>
          <ArrowBackIosIcon style={styles.icon} />
        </button>
        <button onClick={handleNext} style={styles.button}>
          <ArrowForwardIosIcon style={styles.icon} />
        </button>
      </div>
    </div>
  );
}

const styles = {
  carousel: {
    position: "relative",
    width: "100%",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 0,
  },
  controls: {
    position: "absolute",
    top: "50%",
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px",
    transform: "translateY(-50%)",
    zIndex: 1,
  },
  button: {
    backgroundColor: "transparent",
    color: "white",
    border: "none",
    borderRadius: "10px",
    padding: "20px 20px",
    cursor: "pointer",
    fontSize: "40px",
    fontWeight: "bold",
    zIndex: 1,
  },
};
