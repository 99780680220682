import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import MapIcon from "@mui/icons-material/Map";
import logoCT from "assets/images/Logos/logo-ct-dark.png";
import "./Footer.css"; // Importa el archivo CSS

const Footer = () => {
  const date = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="footer-container">
        {/* Sección de la marca */}
        <div className="footer-brand">
          <img src={logoCT} alt="Peroli Centro Dermatológico" className="footer-logo" />
          <h3>Peroli Centro Dermatológico</h3>
        </div>

        {/* Sección de redes sociales */}
        <div className="footer-socials">
          <h4>Redes Sociales</h4>
          <a href="https://www.facebook.com/CentroDermaPeroli/" target="_blank" rel="noreferrer">
            <FacebookIcon />
          </a>
          <a href="https://www.instagram.com/CentroDermaPeroli/" target="_blank" rel="noreferrer">
            <InstagramIcon />
          </a>
        </div>

        {/* Sección de contacto */}
        <div className="footer-contact">
          <h4>Contacto</h4>
          <p>
            <PhoneIcon /> <a href="tel:3521118347">352 111 8347</a>
          </p>
          <p>
            <EmailIcon /> <a href="mailto:dermaperoli@gmail.com">dermaperoli@gmail.com</a>
          </p>
          <p>
            <MapIcon />{" "}
            <a href="https://www.google.com/maps/place/Centro+Dermatol%C3%B3gico+Peroli">
              Gran Via, Gral. Enrique Ramírez 48-Local 4, Plaza, La Piedad de Cabadas, Mich.
            </a>
          </p>
        </div>
      </div>

      {/* Sección de copyright */}
      <div className="footer-copyright">
        <p>Todos los derechos reservados. &copy; {date} Peroli Centro Dermatológico</p>
      </div>
    </div>
  );
};

export default Footer;
